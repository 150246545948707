import * as SsrHttpService from '@http/ssr-http.service';
import * as CsrHttpService from '@http/csr-http.service';
import { hasWindow } from '@services/http.service';

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

import {
    ADD_COUNTER_PARTY,
    ADD_OPPORTUNITY_PAYMENT_SCHEDULES,
    CONFIRM_PAYMENT_URL,
    CREATE_OPPORTUNITIES_V2,
    CREATE_OPPORTUNITITES,
    GET_V2_OPPORTUNITIES_URL,
    GET_COUNTER_PARTIES,
    GET_INDUSTRY_TYPES,
    GET_INVOICE_DISCOUNTING_OPPORTUNITY,
    GET_OPPORTUNITIES_FOR_ADMIN_URL,
    GET_OPPORTUNITIES_URL,
    GET_OPPORTUNITY_BY_SLUG,
    GET_OPPORTUNITY_PROGRESS_URL,
    GET_OPPORTUNITY_TRANCHE_HIGHLIGHTS,
    GET_OPPORTUNITY_URL,
    POST_PAYMENT_GATEWAY_URL,
    TAKE_THE_OPPORTUNITY_LIVE,
    UPDATE_OPPORTUNITY_AS_INVESTMENT_READY,
    UPDATE_OPPORTUNITY_PREFERENCE,
    GET_COUNTER_PARTIES_BY_TYPES,
    GET_OPPORTUNITIES_FILTERS,
    GET_OPPORTUNITIES_STATS,
    UPDATE_OPPORTUNITY_COMMISSION,
    GET_OPPORTUNITY_CURRENT_PRICE,
    UPDATE_OPPORTUNITY_DETAILS,
    GET_OPPORTUNITY_DRAFT_TRANCHE_FOR_OPPORTUNITY_ID,
    GET_OPPORTUNITY_DRAFT_TRANCHE_DETAILS,
    GET_OPPORTUNITY_DRAFT_TRANCHE_LISTING,
    GET_COUNT_OF_COHORT,
    UPDATE_OPPORTUNITY_MAX_INVESTMENT_AMOUNT,
    GET_OPPORTUNITY_DOCUMENTS_URL,
    GET_OPPORTUNITY_FAQS_URL,
    GET_OPPORTUNITY_V2_URL,
    ADD_PRE_OFFER_IRR,
    GET_BRANDS_BY_ASSET_TYPE,
    GET_TAGS_BY_OPPORTUNITY_ID,
    GET_TAGS_BY_BRAND_ID,
    CREATE_OPPORTUNITIES_TAGS,
    HIDE_OPPORTUNITY,
    GET_HIDE_UNHIDE_REASON,
    GET_TAGS_ASSET_TYPE,
    CHECK_OPPORTUNITY_NOT_AVAILABLE_FOR_LINKING,
} from '@services/urls/opportunity';

export const getOpportunity = (id, ctx = null) => {
    const url = GET_OPPORTUNITY_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityProgress = ({ opportunityId, isFTI = false, ctx = null }) => {
    const url = GET_OPPORTUNITY_PROGRESS_URL(opportunityId, isFTI);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityV2 = (id, ctx = null) => {
    const url = GET_OPPORTUNITY_V2_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityDocuments = (id, ctx) => {
    const url = GET_OPPORTUNITY_DOCUMENTS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityFAQs = (id, ctx) => {
    const url = GET_OPPORTUNITY_FAQS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const confirmPayment = (
    amount,
    paymentMethod,
    investorId,
    opportunityId,
    markedBy,
    ctx = null
) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(CONFIRM_PAYMENT_URL(), {
            amount,
            paymentMethod,
            investorId,
            opportunityId,
            markedBy,
        });
    }
    return SsrHttpService.postWithAuth(
        CONFIRM_PAYMENT_URL(),
        { amount, paymentMethod, investorId, opportunityId, markedBy },
        ctx
    );
};

export const getOpportunities = (params, ctx = null) => {
    const { status } = params;
    let url;
    if (status === 'open' || status === 'closed') {
        url = GET_V2_OPPORTUNITIES_URL(params);
    } else {
        url = GET_OPPORTUNITIES_URL(params);
    }

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunitiesByFilter = (params, ctx = null) => {
    const url = GET_OPPORTUNITIES_FILTERS(params);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvoiceDiscountingOpportunity = (entityId) => {
    const url = GET_INVOICE_DISCOUNTING_OPPORTUNITY(entityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunitiesForAdmin = (
    filter,
    page,
    limit,
    searchOpportunityIdText,
    searchCounterPartyText,
    ctx = null
) => {
    const url = GET_OPPORTUNITIES_FOR_ADMIN_URL(
        filter,
        page,
        limit,
        searchOpportunityIdText,
        searchCounterPartyText
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityBySlug = (slug) => {
    const url = GET_OPPORTUNITY_BY_SLUG(slug);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const getIndustryTypes = (ctx = null) => {
    const url = GET_INDUSTRY_TYPES();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getCounterParties = (ctx = null) => {
    const url = GET_COUNTER_PARTIES();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createOpportunity = (entity, ctx = null) => {
    const url = CREATE_OPPORTUNITITES();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, entity);
    }
    return SsrHttpService.postWithAuth(url, entity, ctx);
};

export const createOpportunityV2 = (entity, ctx = null) => {
    const url = CREATE_OPPORTUNITIES_V2();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, entity);
    }
    return SsrHttpService.postWithAuth(url, entity, ctx);
};

export const opportunityPaymentGatway = (entity, ctx = null) => {
    const url = POST_PAYMENT_GATEWAY_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, entity);
    }
    return SsrHttpService.postWithAuth(url, entity, ctx);
};
export const getOpportunityTrancheHighlights = (id, ctx = null) => {
    const url = GET_OPPORTUNITY_TRANCHE_HIGHLIGHTS(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const addOpportunityPaymentSchedules = (data, ctx = null) => {
    const url = ADD_OPPORTUNITY_PAYMENT_SCHEDULES();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const takeTheDealLive = (id, data, ctx = null) => {
    const url = TAKE_THE_OPPORTUNITY_LIVE(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const hideOpportunity = (id, data, ctx = null) => {
    const url = HIDE_OPPORTUNITY(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.getWithAuth(url, data, ctx);
};

export const addPreOfferIRR = (data, ctx = null) => {
    const url = ADD_PRE_OFFER_IRR();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const addCounterParty = (data, ctx = null) => {
    const url = ADD_COUNTER_PARTY();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateOpportunityPreferenceService = (id, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_PREFERENCE(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateOpportunityAsInvestmentReady = (id, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_AS_INVESTMENT_READY(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getOpportunitiesStats = (params, ctx = null) => {
    const url = GET_OPPORTUNITIES_STATS(params);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getCounterPartiesByType = (type, ctx = null) => {
    const url = GET_COUNTER_PARTIES_BY_TYPES(type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateOpportunityDetails = (id, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_DETAILS(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateOpportunityCommissionPercentage = (id, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_COMMISSION(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const updateOpportunityMaxInvestmentAmount = (id, data, ctx = null) => {
    const url = UPDATE_OPPORTUNITY_MAX_INVESTMENT_AMOUNT(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const getOpportunityCurrentPrice = (id, data, ctx = null) => {
    const url = GET_OPPORTUNITY_CURRENT_PRICE(id);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getOpportunityDraftTrancheForOpportunityId = (id, ctx = null) => {
    const url = GET_OPPORTUNITY_DRAFT_TRANCHE_FOR_OPPORTUNITY_ID(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityDraftTrancheDetails = (id, ctx = null) => {
    const url = GET_OPPORTUNITY_DRAFT_TRANCHE_DETAILS(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getOpportunityDraftTrancheListing = (
    status,
    limit,
    offset,
    ctx = null
) => {
    const url = GET_OPPORTUNITY_DRAFT_TRANCHE_LISTING(status, limit, offset);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getCountOfCohort = (data, ctx = null) => {
    const url = GET_COUNT_OF_COHORT();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getBrandsByAssetType = (assetTypeId, ctx = null) => {
    const url = GET_BRANDS_BY_ASSET_TYPE(assetTypeId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getTagsByOpportunityId = (opportunityId, ctx = null) => {
    const url = GET_TAGS_BY_OPPORTUNITY_ID(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getTagsByBrandId = (brandId, ctx = null) => {
    const url = GET_TAGS_BY_BRAND_ID(brandId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getTagsByAssetType = (assetType, ctx = null) => {
    const url = GET_TAGS_ASSET_TYPE(assetType);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createOpportunityTags = (opportunityId, data, ctx = null) => {
    const url = CREATE_OPPORTUNITIES_TAGS(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getHideUnhideReason = (id, actionType, ctx = null) => {
    const url = GET_HIDE_UNHIDE_REASON(id, actionType);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}

export const checkOpportunityNotAvailableForLinking = (id, ctx = null) => {
    const url = CHECK_OPPORTUNITY_NOT_AVAILABLE_FOR_LINKING(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
