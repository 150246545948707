import { UrlParamsReplace, getUrlFromMultipleQueryParams } from './index';

export const GET_OPPORTUNITY_URL = (id) =>
    UrlParamsReplace('/opportunities/:id', { id });

export const GET_OPPORTUNITY_V2_URL = (id) =>
    UrlParamsReplace('/v2/opportunities/:id', { id });

export const GET_OPPORTUNITY_DOCUMENTS_URL = (id) =>
    UrlParamsReplace('/opportunity/:id/documents', { id });

export const GET_OPPORTUNITY_FAQS_URL = (id) =>
    UrlParamsReplace('/opportunity/:id/faqs', { id });

export const GET_OPPORTUNITY_PROGRESS_URL = (opportunityId, isFTI) =>
    UrlParamsReplace(
        '/opportunities/:opportunityId/progress?isFTI=:isFTI',
        {
            opportunityId,
            isFTI,
        }
    );
export const CONFIRM_PAYMENT_URL = () => UrlParamsReplace('/payments');

export const GET_V2_OPPORTUNITIES_URL = (params) =>
    getUrlFromMultipleQueryParams('/v2/opportunities', params);

export const GET_OPPORTUNITIES_FILTERS = (params) =>
    getUrlFromMultipleQueryParams('/v2/opportunities/filters', params);

export const GET_OPPORTUNITIES_URL = (filter) =>
    UrlParamsReplace('/opportunities?filter=:filter', { filter });

export const GET_OPPORTUNITIES_FOR_ADMIN_URL = (
    filter,
    page,
    limit,
    searchOpportunityIdText,
    searchCounterPartyText
) =>
    UrlParamsReplace(
        '/admin/opportunities?filter=:filter&page=:page&limit=:limit&searchOpportunityIdText=:searchOpportunityIdText&searchCounterPartyText=:searchCounterPartyText',
        { filter, page, limit, searchOpportunityIdText, searchCounterPartyText }
    );

export const GET_OPPORTUNITY_BY_SLUG = (slug) =>
    UrlParamsReplace('/opportunity-details/:slug', { slug });

export const GET_INDUSTRY_TYPES = () => UrlParamsReplace('/industryTypes');

export const GET_COUNTER_PARTIES = () => UrlParamsReplace('/counterParties');

export const CREATE_OPPORTUNITITES = () => UrlParamsReplace('/v1/opportunity');

export const CREATE_OPPORTUNITIES_V2 = () =>
    UrlParamsReplace('/v2/opportunity');

export const POST_PAYMENT_GATEWAY_URL = () =>
    UrlParamsReplace('/payment-gateway');

export const GET_INVOICE_DISCOUNTING_OPPORTUNITY = (id) =>
    UrlParamsReplace('/invoice-discounting-opportunity/:id', { id });

export const GET_OPPORTUNITY_TRANCHE_HIGHLIGHTS = (id) =>
    UrlParamsReplace('/opportunities/:id/tranche/highlights', { id });

export const CREATE_OPPORTUNITY_TRANCHE = (id) =>
    UrlParamsReplace('/opportunities/:id/tranche', { id });

export const UPDATE_OPPORTUNITY_TRANCHE = (id) =>
    UrlParamsReplace('/opportunities/:id/tranche', { id });

export const ADD_OPPORTUNITY_PAYMENT_SCHEDULES = () =>
    UrlParamsReplace('/opportunity-payment-schedules');

export const TAKE_THE_OPPORTUNITY_LIVE = (id) =>
    UrlParamsReplace(`/opportunities/${id}/takeTheOpportunityLive`);

export const HIDE_OPPORTUNITY = (id) =>
    UrlParamsReplace(`/opportunities/${id}/hide`);

export const ADD_PRE_OFFER_IRR = () =>
    UrlParamsReplace('/opportunity-pre-festive-offer');

export const ADD_COUNTER_PARTY = () => UrlParamsReplace('/counter-party');

export const UPDATE_OPPORTUNITY_PREFERENCE = (id) =>
    UrlParamsReplace(`/opportunities/${id}/preference`);

export const UPDATE_OPPORTUNITY_AS_INVESTMENT_READY = (id) =>
    UrlParamsReplace(`/opportunities/${id}/markInvestmentReady`);

export const GET_OPPORTUNITIES_STATS = (params) =>
    getUrlFromMultipleQueryParams('/v2/opportunities/stats', params);

export const GET_COUNTER_PARTIES_BY_TYPES = (type) =>
    UrlParamsReplace(`/counter-parties?type=:type`, { type });

export const UPDATE_OPPORTUNITY_DETAILS = (id) =>
    UrlParamsReplace(`/opportunity/${id}`);

export const UPDATE_OPPORTUNITY_COMMISSION = (id) =>
    UrlParamsReplace(`/opportunities/${id}/commision`);

export const UPDATE_OPPORTUNITY_MAX_INVESTMENT_AMOUNT = (id) =>
    UrlParamsReplace(`/opportunities/${id}/max-investment-amount`);

export const GET_OPPORTUNITY_CURRENT_PRICE = (id) =>
    UrlParamsReplace(`/debenture-price/${id}`);

export const GET_OPPORTUNITY_DRAFT_TRANCHE_FOR_OPPORTUNITY_ID = (id) =>
    UrlParamsReplace('/opportunity-draft-tranche?id=:id', { id });

export const GET_OPPORTUNITY_DRAFT_TRANCHE_DETAILS = (id) =>
    UrlParamsReplace('/opportunity-draft-tranche-details/:id', { id });

export const GET_OPPORTUNITY_DRAFT_TRANCHE_LISTING = (status, limit, offset) =>
    UrlParamsReplace(
        '/opportunity-draft-tranche-listing?status=:status&limit=:limit&offset=:offset',
        {
            status,
            limit,
            offset,
        }
    );

export const GET_COUNT_OF_COHORT = () =>
    UrlParamsReplace('/count-of-cohort-for-deal-visibility');

export const GET_BRANDS_BY_ASSET_TYPE = (assetTypeId) =>
    UrlParamsReplace('/brands/asset-type/:assetTypeId', { assetTypeId });

export const GET_TAGS_BY_OPPORTUNITY_ID = (opportunityId) =>
    UrlParamsReplace('/opportunity/:opportunityId/tags', { opportunityId });

export const GET_TAGS_BY_BRAND_ID = (brandId) =>
    UrlParamsReplace('/tags/brands/:brandId', { brandId });

export const GET_TAGS_ASSET_TYPE = (assetType) => UrlParamsReplace('/tags/assetTypes/:assetType', { assetType });

export const CREATE_OPPORTUNITIES_TAGS = (opportunityId) =>
    UrlParamsReplace('/opportunity/:opportunityId/tags', { opportunityId });

export const GET_HIDE_UNHIDE_REASON = (id, actionType) =>
    UrlParamsReplace(
        '/hide-unhide-reason/:id?actionType=:actionType',
        {
            id,
            actionType,
        }
    );

export const CHECK_OPPORTUNITY_NOT_AVAILABLE_FOR_LINKING = (opportunityId) =>
    UrlParamsReplace('/opportunity/:opportunityId/check-not-suitable-for-linking', {opportunityId});